import * as React from "react"
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby";
import SEO from "../components/seo";

export const query = graphql`
  query WizardPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
     ... fragSiteSettings
    }
    post: sanityLandingPage(slug: { current: { eq: "wealth-wizards" } }) {
      ... fragLandingPage
      content {
        ... fragSanityCourseTitle
      ... fragSanityAvailableCourses
      ... fragSanityTabsTextboxBlock
      ... fragSanityDropdownWidget
      ... fragSanitySocialMediaPosts
      ... fragSanityTwitterBlock
      ... fragSanityLandingSearchBlock
      ... fragSanityBestBuysTable
      ... fragSanityRecProviders
      ... fragSanityTipChooser
      ... fragSanityBreadcrumb
      ... fragSanityPopularConversationsBlock
      ... fragSanityBigBlockGroup
      ... fragSanityDataCaptureForm
      ... fragSanityHeroSection
      ... fragSanityHeaderBlock
      ... fragSanityTitleSection
      ... fragSanityLatestArticlesBlock
      ... fragSanityCalcReference
      ... fragSanityFilteredQuestionsBlock
      ... fragSanityAdvisersList
      ... fragSanityFundList
      ... fragSmallSpacerBlock
      ... fragWidgetSelection
        ... fragSanityEmailCaptureForm
       
      }
    }
   
  }
`;


const WizardIndexPage = (props) => {

  const { data, errors } = props;
  const post = data && data.post;

  console.log("data",data)

  return (
    <>
    <Layout>
      <Container>
        
          <div id="pension-guidance" context-root="wealthwizard"></div>
        
      </Container>
    </Layout>

    <Helmet>
        <script defer src="https://turo-green.wealthwizards.io/pension-guidance/main.js"/>
      </Helmet>
    </>
  );

}
export default WizardIndexPage;
